<template>
  <div>
    <component
      :is="component"
      :name="component"
      ref="form"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'OnboardingVideoIdent',
  components: {
    Information: lazyLoad('views/Onboarding/VideoIdent/Information'),
    Selection: lazyLoad('views/Onboarding/VideoIdent/Selection'),
  },
  data() {
    return {
      getUserInterval: null,
    };
  },
  computed: {
    ...mapGetters('user', [
      'subStage',
      'mainStage',
    ]),
    component() {
      return this.$route.params.step;
    },
  },
  watch: {
    subStage(step) {
      if (step !== 'Overview') {
        this.$router.push({
          name: 'Onboarding.VideoIdent',
          params: {
            step,
          },
        });
      }
    },
  },
  async created() {
    this.fetchNewData();
    this.getUserInterval = setInterval(() => {
      this.fetchNewData();
    }, 10000);
  },
  destroyed() {
    clearInterval(this.getUserInterval);
  },
  methods: {
    ...mapActions('user', [
      'setMainStage',
      'setSubStage',
      'updateOnboarding',
      'updateUser',
    ]),
    ...mapActions('onboarding', [
      'setLoading',
    ]),
    async fetchNewData() {
      await this.$store.dispatch('user/getUser', null, { root: true });
      if (this.mainStage === 'contractClosing') {
        this.$router.push({
          name: 'Onboarding.Overview',
        });
      }
    },
    async next() {
      this.setLoading(true);
      if (this.component === 'Selection') {
        const input = {
          investor: {},
          mainStage: 'videoIdent',
          subStage: 'selection',
        };
        const overviewInput = {
          investor: {},
          mainStage: 'videoIdent',
          subStage: 'overview',
        };
        await this.updateOnboarding({
          input,
        });
        await this.updateOnboarding({
          input: overviewInput,
        });
        this.setMainStage('contractClosing');
        this.setSubStage('Overview');
        this.setLoading(false);
        return this.$router.push({ name: 'Onboarding.Overview' });
      }
      if (this.component === 'Information') {
        const input = {
          investor: {},
          mainStage: 'videoIdent',
          subStage: 'information',
        };
        await this.updateOnboarding({
          input,
        });
      }
      this.setLoading(false);
      return this.setSubStage('selection');
    },
  },
};
</script>
